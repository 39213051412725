<template>
	<div id="fileIo">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>办公</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">我的公文</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f">
					<div class=" right">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据
								<!-- <span style="color: #007AFF;">已选择0条</span> -->
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入名称搜索" v-model="sousuo" size="small" style="margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" type="primary" @click="search">搜索</el-button>
							</div>
						</div>
						<el-table :data="assetList" height="88%" style="width: 100%;border: 1px solid #EEEEEE;">
							<el-table-column type="index" label="序号" align="center"></el-table-column>
							<el-table-column label="文件" align="center">
								<template slot-scope="scope">
									<div class="u-f-justify">
										<img src="/static/image/excel.png" style="width: 20px;margin-right: 5px;">
										<a target="_blank" :href="'http://view.officeapps.live.com/op/view.aspx?src='+scope.row.file.fullurl">{{scope.row.file.originname}}</a>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="大小" align="center">
								<template slot-scope="scope">
									<div>{{scope.row.file.originsize}}kb</div>
								</template>
							</el-table-column>
							<el-table-column prop="origin_units" label="来源单位" align="center"></el-table-column>
							<el-table-column prop="describe" label="文件摘要" align="center"></el-table-column>
							<el-table-column prop="opinion" label="办理意见" align="center"></el-table-column>
							<el-table-column prop="origin_time" label="来源日期" align="center"></el-table-column>
							<el-table-column label="紧急程度" align="center">
								<template slot-scope="scope">
									<el-tag v-if="scope.row.level==1">普通</el-tag>
									<el-tag v-if="scope.row.level==2" type="success">紧急</el-tag>
									<el-tag v-if="scope.row.level==3" type="info">特急</el-tag>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="120" align="center">
								<template slot-scope="scope">
									<el-button @click="download(scope.row.file.fullurl)" type="text" size="small">下载</el-button>
									<el-button @click="read(scope.row.id)" type="text" size="small" :disabled="scope.row.read_time!=null">已阅</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			total: 0,
			assetList: [],
			sousuo: '',
			page: 1,
			limit: 10,
		};
	},
	mounted() {
		this.getMyDocumentList()
	},
	methods: {
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getMyDocumentList()
		},
		search(){
			if(this.sousuo){
				this.page = 1;
				this.getMyDocumentList()
			}
		},
		// 获取资产列表
		getMyDocumentList() {
			let data = {};
			let data2 = {};
			if(this.sousuo){
				data.title = this.sousuo;
				data2.filter = JSON.stringify(data);
				data2.op = {"title":"like"};
			}
			this.$api.file.getMyDocumentList({type:1}).then(res => {
				if (res.data.code == 1) {
					this.assetList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			});
		},
		read(id){
			this.$api.file.readFile({msg_id:id}).then(res=>{
				if(res.data.code==1){
					this.$message.success('操作成功')
					this.getMyDocumentList();
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		download(url){
			window.location.href = url;
		}
	}
};
</script>

<style lang="scss">
#fileIo {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.72vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.78125vw;
			}
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 85vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.actives{
					background-color: #e4f1ff;
					color: #007aff;
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						cursor: pointer;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 80vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>

